/* Map.css */
.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

/* Input container for destination search */
.input-container {
  position: relative;
  width: 600px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Icon for location dot (FaLocationDot) */
.input-icon {
  margin-right: 10px;
  font-size: 20px;
  color: #ff4e42; /* Set the color to something noticeable */
}

/* Search input field */
#destination {
  width: 100%;
  border: none;
  outline: none;
  padding: 8px;
  font-size: 16px;
}

/* Search button/icon (HiOutlineSearch) */
.search-icon {
  position: absolute;
  right: 10px;
  font-size: 20px;
  color: #007bff;
}

/* Suggestions list dropdown */
.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  border-radius: 5px;
  z-index: 10;
}

/* Suggestion item */
.suggestion-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.suggestion-item:hover {
  background-color: #f1f1f1;
}

/* Directions display container */
#instructions {
  margin-top: 20px;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Direction header (Trip duration and distance) */
.direction-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

/* Reset button */
.resetButton {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

.resetButton:hover {
  background-color: #0056b3;
}

/* Ordered list for directions */
#instructions ol {
  margin-top: 10px;
  list-style-type: decimal;
  padding-left: 20px;
}

#instructions li {
  margin-bottom: 5px;
}

.nearest-restrooms {
  position: absolute;
  top: 20px;
  left: 50px;
  z-index: 10;
  background-color: #ffffffbb;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
#instructions {
  position: absolute;
  top: 80px; /* Adjust to position right under the controls with some spacing */
  right: 50px; /* Align with the input container */
  width: 320px;
  max-height: 70%;
  background-color: #ffffffcc;
  overflow-y: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  z-index: 20;
  font-family: sans-serif;
  display: flex; /* Keep flex for alignment */
  flex-direction: column; /* Stack items vertically */
}

.direction-header {
  display: flex;
  flex-direction: row;
  background-color: #c6f6d59b;
  padding: 20px;
  align-items: center; /* Center items vertically */
}

.direction-header span {
  display: flex; /* Ensure icons are aligned */
  align-items: center; /* Center icons vertically */
  margin-right: 10px; /* Adjust space between icon and text */
}

.direction-header p {
  margin: 0; /* Remove margin to align text with icons */
  font-size: 1rem;
}

#instructions ol {
  padding: 20px 20px 20px 40px;
}

.show-restrooms {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}